import Link from '@material-ui/core/Link'
import { styled } from '@material-ui/core/styles'

import { rspTheme } from '../../../styles/rspTheme'

export const LinkToMainContent = styled(Link)({
  padding: `2rem`,
  display: `flex`,
  justifyContent: `center`,
  alignItems: `center`,
  width: `300px`,
  position: `absolute`,
  left: `calc(50% - 150px)`,
  transform: `translateY(-100%)`,
  transition: `transform 0.3s ease`,
  zIndex: 150,

  backgroundColor: rspTheme.palette.primary.background,
  borderRadius: `0px 0px 8px 8px`,
  borderRight: `2px solid ${rspTheme.palette.primary.main}`,
  borderLeft: `2px solid ${rspTheme.palette.primary.main}`,
  borderBottom: `2px solid ${rspTheme.palette.primary.main}`,
  color: rspTheme.palette.primary.main,
  textDecoration: `none`,
  fontWeight: 400,

  '&:focus': {
    outline: `none`,
    transform: `translateY(0%)`,
  },
})
