import React from 'react'

import { LinkToMainContent } from './styles'

interface SkipLinkProps {
  linkTarget: string
  linkText: string
}

const SkipLink = (props: SkipLinkProps) => {
  const { linkTarget, linkText } = props

  return (
    <LinkToMainContent href={`#${linkTarget}`}>{linkText}</LinkToMainContent>
  )
}

export default SkipLink
